<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="60%"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Contatos
          <span
            v-if="association"
            class="body-2"
          >
            <i>#{{ association.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="association">
        <v-row class="pt-5 pb-2">
          <v-col>
            <p class="font-weight-black text-uppercase ma-0">
              {{ association.name }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="success"
              class="mr-2"
              dark
              small
              @click="openDialog()"
            >
              <v-icon
                left
                v-text="'mdi-plus'"
              />
              Adicionar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-sheet
              elevation="0"
              outlined
            >
              <v-simple-table
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-left">
                        Nome
                      </th>
                      <th class="text-left">
                        E-mail
                      </th>
                      <th class="text-left">
                        Telefone
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="contacts.length <=0 "
                    >
                      <td
                        colspan="5"
                        class="title"
                      >
                        <i>Nenhum contato encontrado</i>
                      </td>
                    </tr>
                    <tr
                      v-for="(contact, index) in contacts"
                      :class="{ 'grey--text': !contact.active }"
                      :key="index"
                    >
                      <td>{{ contact.typeOfContactAssociation.description }}</td>
                      <td>{{ contact.name }}</td>
                      <td>{{ contact.email }}</td>
                      <td>{{ contact.telephone }}</td>
                      <td class="text-right">
                        <v-btn
                          icon
                          small
                          @click="openDialog(contact)"
                        >
                          <v-icon
                            v-text="'mdi-pencil'"
                            small
                          />
                        </v-btn>
                        <v-btn
                          icon
                          small
                          :loading="loadingDelete === contact.id"
                          @click="deleteContact(contact)"
                        >
                          <v-icon
                            v-text="'mdi-delete'"
                            small
                          />
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-associations-contacts-fields
      v-model="showDialogContact"
      :association-id="associationId"
      :key="`edit-${editContactId}-${newKey}`"
      :contact="editContact"
      @save="load"
    />

  </v-dialog>
</template>

<script>
  import associationsApi from '@/api/associations'

  export default {

    components: {
      DialogAssociationsContactsFields: () => import('@/components/dialog/associations-contacts/DialogAssociationsContactsFields'),
    },

    props: {
      value: {
        type: Boolean,
      },
      associationId: {
        type: Number,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        loadingDelete: null,
        showDialogContact: false,
        editContact: null,
        association: null,
        contacts: [],
        newKey: 0,
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      editContactId () {
        return this.editContact ? this.editContact.id : 0
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    methods: {
      async load () {
        try {
          this.loading = true

          this.association = null
          this.contacts = []

          const responseAssociation = await associationsApi.get(this.associationId)
          const responseContacts = await associationsApi.listContacts(this.associationId)

          this.association = responseAssociation.data.association
          this.contacts = responseContacts.data.contacts
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (contact = null) {
        if (!contact) {
          this.newKey++
        }

        this.editContact = contact
        this.showDialogContact = true
      },

      async deleteContact (contact) {
        try {
          this.loadingDelete = contact.id

          await associationsApi.deleteContact(this.associationId, contact.id)

          this.load()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = null
        }
      },

    },

  }
</script>
